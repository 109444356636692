import React from "react"
import SEO from "../components/seo"
import { motion } from 'framer-motion'
import i from '../content/issues/I/0000.png'
import ii from '../content/issues/I/0001.png'
import iii from '../content/issues/I/0002.png'
import Slider from "react-slick";
import downloadFile from '../content/issues/I/I.pdf' 
import ARROW_left from '../constants/left-arrow.svg'
import ARROW_right from '../constants/right-arrow.svg'


var settings = {
  dots: true,
  className: "center",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
}; 

const duration = 0.35

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={ARROW_right} alt="arrow_right"/>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={ARROW_left} alt="arrow_left"/>
    </div>
  );
}

const IssuesPage = () => {
  return (
    <>
    <link
      rel="stylesheet"
      type="text/css"
      charset="UTF-8"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
    />

      <SEO title="XORIOR Magazine" />
      <motion.section
        variants={container}
        initial="hidden" 
        animate="visible"
        className="container"
      >
        <motion.div 
          className="content"
          variants={item}
          transition="easeInOut"
        >
          <p className="text-lg md:text-xl pl-3">XORIOR I</p>

        </motion.div>

        <motion.div 
          className="content pl-5 pb-5"
          variants={item}
          transition="easeInOut"
        >
          <a className="hover:text-yellow-500" href={downloadFile} download>Download to view the full magazine here.</a>{` `}
        </motion.div>


        <motion.div 
          className="content pl-5"
          variants={item}
          transition="easeInOut"
        >
          <Slider className="justify-items-center" {...settings}>
          <div>
          <img src={i} alt="XORIOR cover" class="max-w-max"/>
          </div>
          <div>
          <img src={ii} alt="mission statement" class="max-w-max"/>
          </div>
          <div>
          <img src={iii} alt="table of contents" class="max-w-max"/>
          </div>
          </Slider>
        </motion.div>



      </motion.section>
    </>
  )
}

export default IssuesPage